import { learningCard } from "./learning_card";

export const ja = {
  language: "言語",
  learningCard
  // テスト用
  //   menu: ["ホーム"],
  //   test: "テスト",
  //   object: {
  //     a: "1233"
  //   },
  //   parse: "welcome to {name}",
  //   parses: {
  //     a: "welcome to {name}"
  //   },
};
