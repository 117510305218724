export const learningCard = {
  top: {
    title: "学習カード",
    noUnitMessage: "登録されている単元がありません。",
    fetching: "情報取得中"
  },
  modal: {
    title: "振り返り",
    noDataMessage: "表示するデータがありません。",
    noCommentMessage: "まだコメントがありません。",
    dateHeading: "日付",
    contentHeading: "内容",
    unitReflectionHeading: "単元を通しての振り返り",
    selfReflection: "自身の振り返り",
    teacherReflection: "先生からの振り返り",
    aiReflection: "AIの振り返り"
  }
};
