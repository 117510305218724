// StudentList view用のvuex

import { getStudentList, getStatusList } from "@/api/student";
import dayjs from "dayjs";
import { Module } from "vuex";
import { RootState } from "..";
import {
  RoomSummaryWithRunningRegistrants,
  RunningRegistrant
} from "@/entities/room";
import { RunningTimer } from "@/entities/learning";

interface StudentListState {
  learningTerm: string;
  termStart: string;
  termStartTime: number;
  termEnd: string;
  termEndTime: number;
  baseDatas: { [key: string]: string | number | boolean }[];
  beforeLoad: boolean;
  runningRoomRegistrants: RunningRegistrant[];
  runningTimers: RunningTimer[];
}

const studentListModule: Module<StudentListState, RootState> = {
  namespaced: true,

  state: {
    learningTerm: "過去7日間",
    termStart: "",
    termStartTime: 0,
    termEnd: "",
    termEndTime: 0,
    baseDatas: [],
    beforeLoad: true,
    runningRoomRegistrants: [], // 現在どこかの学習室に入室中の生徒の情報
    runningTimers: [] // 現在タイムキーパー利用中の生徒の情報
  },

  getters: {},

  mutations: {
    SET_LEARNING_TERM(state, term: string) {
      state.learningTerm = term;
      if (term === "過去7日間") {
        const end = dayjs()
          .locale("ja")
          .add(-1, "day")
          .endOf("day");
        const start = dayjs()
          .locale("ja")
          .add(-7, "day")
          .startOf("day");
        state.termEnd = end.format("YYYY-MM-DD");
        state.termEndTime = end.unix();
        state.termStart = start.format("YYYY-MM-DD");
        state.termStartTime = start.unix();
      } else if (term === "過去4週間") {
        const end = dayjs()
          .locale("ja")
          .add(-1, "day")
          .endOf("day");
        const start = dayjs()
          .locale("ja")
          .add(-28, "day")
          .startOf("day");
        state.termEnd = end.format("YYYY-MM-DD");
        state.termEndTime = end.unix();
        state.termStart = start.format("YYYY-MM-DD");
        state.termStartTime = start.unix();
      }
    },
    SET_TERM_START(state, start: string) {
      state.termStart = start;
      state.termStartTime = dayjs(state.termStart)
        .locale("ja")
        .startOf("day")
        .unix();
      const startData = dayjs.unix(state.termStartTime);
      const endData = dayjs.unix(state.termEndTime);
      if (endData.diff(startData, "day") <= 0) {
        state.termEnd = start;
        state.termEndTime = dayjs(state.termEnd)
          .locale("ja")
          .endOf("day")
          .unix();
      }
    },
    SET_TERM_END(state, end: string) {
      state.termEnd = end;
      state.termEndTime = dayjs(state.termEnd)
        .locale("ja")
        .endOf("day")
        .unix();
      const startData = dayjs.unix(state.termStartTime);
      const endData = dayjs.unix(state.termEndTime);

      if (endData.diff(startData, "day") <= 0) {
        state.termStart = end;
        state.termStartTime = dayjs(state.termStart)
          .locale("ja")
          .startOf("day")
          .unix();
      }
    },
    SET_STUDENT_LIST_DATAS(
      state,
      datas: { [key: string]: string | number | boolean }[]
    ) {
      if (datas.length === 0) {
        return;
      }
      state.baseDatas = datas;
      state.beforeLoad = false;
    },
    SET_RUNNING_ROOM_REGISTRANTS(state, payload) {
      state.runningRoomRegistrants = payload;
    },
    SET_RUNNING_TIMERS(state, payload) {
      state.runningTimers = payload;
    },
    CLEAR(state) {
      state.learningTerm = "過去7日間";
      state.termStart = "";
      state.termStartTime = 0;
      state.termEnd = "";
      state.termEndTime = 0;
      state.baseDatas = [];
      state.beforeLoad = true;
    }
  },

  actions: {
    async getStudentListDatas(context) {
      const datas = await getStudentList(
        context.state.termStartTime,
        context.state.termEndTime
      );
      // "suf-name"とはログインIDのことである
      context.commit("SET_STUDENT_LIST_DATAS", datas);
    },
    async getStatusList(context, { type, tutorDocId }) {
      const { rooms, timers } = await getStatusList({ type, tutorDocId });
      context.commit(
        "SET_RUNNING_ROOM_REGISTRANTS",
        rooms
          .map(
            (room: RoomSummaryWithRunningRegistrants) => room.runningRegistrants
          )
          .flat()
      );
      context.commit("SET_RUNNING_TIMERS", timers);
    }
  }
};

export default studentListModule;
