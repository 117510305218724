import { Lang } from "@/entities/lang";

const langKey = "lang";

export function getLangFromLocalStorage() {
  const lang = localStorage.getItem(langKey);
  return lang ? lang : "ja";
}

export function setLangInLocalStorage(lang: Lang) {
  localStorage.setItem(langKey, lang);
}

export function clearLangFromLocalStorage() {
  localStorage.removeItem(langKey);
}
