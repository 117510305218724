export const learningCard = {
  top: {
    title: "Smart Reflection Card",
    noUnitMessage: "There is not unit to show.",
    fetching: "Loading data"
  },
  modal: {
    title: "Reflection",
    noDataMessage: "There is nothing to show here.",
    noCommentMessage: "No comments yet.",
    dateHeading: "Date",
    contentHeading: "Content",
    unitReflectionHeading: "Reflection Through the Unit",
    selfReflection: "Personal Reflection",
    teacherReflection: "Teacher's Reflection",
    aiReflection: "AI's Reflection"
  }
};
