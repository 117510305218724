import firebase from "firebase/app";
import { SchoolAiConfig } from "./school_ai_config";

export const adminCollectionKey = "admins";
export const systemSettingCollectionKey = "systemSettings";

export type Admin = {
  ref: firebase.firestore.DocumentReference;
  name: string;
  isSuperAdmin?: boolean;
  prompt?: { docId: string; name: string; text: string };
  schoolAiConfig?: SchoolAiConfig;
};

export type NotificationSetting = {
  ref: firebase.firestore.DocumentReference;
  data: NotificationSettingData;
};

export type NotificationSettingData = {
  useAiReply: boolean;
  replyImmediately: boolean;
  min?: number;
  max?: number;
  enterPrompt?: string;
  leavePrompt?: string;
};

export function convertToNotificationSetting(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
) {
  const returnData: NotificationSettingData = {
    useAiReply: data.useAiReply ?? false,
    replyImmediately: data.replyImmediately ?? false
  };

  if (data.min) {
    returnData.min = data.min;
  }

  if (data.max) {
    returnData.max = data.max;
  }

  if (data.enterPrompt) {
    returnData.enterPrompt = data.enterPrompt;
  }

  if (data.leavePrompt) {
    returnData.leavePrompt = data.leavePrompt;
  }

  return { data: returnData, ref };
}
