import { getSchoolRefFromClassroomRef } from "./classroom";
import { LearningType } from "./learning";

import firebase from "firebase/app";

export const studentCollectionKey = "students";

export const studentConfigCollectionKey = "studentConfigs";

export const studentSecretCollectionKey = "studentSecrets";

export type StudentGrade =
  | "小1"
  | "小2"
  | "小3"
  | "小4"
  | "小5"
  | "小6"
  | "中1"
  | "中2"
  | "中3"
  | "高1"
  | "高2"
  | "高3"
  | "その他";

export const elementarySchoolGrades = [
  "小1",
  "小2",
  "小3",
  "小4",
  "小5",
  "小6"
];

export const juniorHighSchoolGrades = ["中1", "中2", "中3"];
export const highSchoolGrades = ["高1", "高2", "高3", "その他"];

export const getGradeIndex = (label: string) => {
  const list = [
    ...elementarySchoolGrades,
    ...juniorHighSchoolGrades,
    ...highSchoolGrades
  ];
  return list.indexOf(label);
};

export type StudentLearning = {
  start: number; // 学習開始日時
  time: number; // 学習時間(秒)
  type?: LearningType;
  labels?: firebase.firestore.DocumentReference[];
};

export type StudentProperty = {
  id: string;
  value: string | number;
};

export type Student = {
  ref: firebase.firestore.DocumentReference;
  data: StudentData;
};

export type StudentData = {
  id: string; // 生徒管理用ID (同塾内 unique)
  name: string; // 生徒名
  grade: StudentGrade; // 学年
  classSchedules: string[]; // 授業曜日・日時
  totalTime: number; // 累計学習時間(秒)
  mingakuTotalTime?: number; // 累計学習時間(秒) みんがく学習室のみ（※ バッジ付与処理用）
  totalDayCount: number; // 累計学習日数
  currentRunningDayCount: number; // 現在の連続学習日数
  maxRunningDayCount: number; // 最長連続学習日数
  maxRunningWeekCount: number; // 最長連続学習週数
  latestLearnings: StudentLearning[]; // 過去28日間以内の学習時間リスト (※ データ同期batch処理の仕組み上、一部28日より前のデータも含まれる)
  latestMessageTime: number; // 最終メッセージ時間
  latestMessageText: string; // 最終メッセージテキスト
  target: firebase.firestore.DocumentReference;
  sundayNotify?: boolean; // 日曜の開室通知設定
  mondayNotify?: boolean; // 月曜の開室通知設定
  tuesdayNotify?: boolean; // 火曜の開室通知設定
  wednesdayNotify?: boolean; // 水曜の開室通知設定
  thursdayNotify?: boolean; // 木曜の開室通知設定
  fridayNotify?: boolean; // 金曜の開室通知設定
  saturdayNotify?: boolean; // 土曜の開室通知設定
  created?: number; // 作成日時 (データ分析用)
  recessTime?: number; // 休会日時(unixtime)
  properties?: StudentProperty[]; // customProperty
  characteristic?: string;
  freeInput?: string;
  prefecture?: string;
  notifiedDayCount?: number; // popy ホーム画面でのモーダル制御用
  emailReceivers?: string[]; // 当studentの入室退室通知のemail受信者
  isNandeUserCreated?: boolean; // NANDEサービスにこのユーザーが登録されているか
  nandeUserId?: string; // NANDEサービスにこのユーザーが登録されているか
  unlinkSchoolAI?: boolean; // SchoolAIとの連携解除ステータス
  unlinkSchoolAIAt?: number; // SchoolAIとの連携解除日時
  relinkSchoolAIAt?: number; // SchoolAIとの連携再開日時
  isLinkedToQanda?: boolean; //Qandaにユーザーが登録されているか
  qandaUserId?: string; //QandaのユーザーID
};

export type StudentConfig = {
  ref: firebase.firestore.DocumentReference;
  data: StudentConfigData;
};

export type StudentConfigData = {
  password: string; // ログイン用パスワード
  loginId?: string;
  lineId?: string; // 連携しているLINEアカウントのID
};

export type StudentSecret = {
  ref: firebase.firestore.DocumentReference;
  data: StudentSecretData;
};

export type StudentSecretData = {
  comment: string; // 管理人用注釈（メモ）
};

export function getStudentConfigRefFromStudentRef(
  studentRef: firebase.firestore.DocumentReference
): firebase.firestore.DocumentReference {
  if (
    studentRef.parent.id !== studentCollectionKey ||
    !studentRef.parent.parent
  ) {
    throw new Error("given data is not student reference");
  }
  return studentRef.parent.parent
    .collection(studentConfigCollectionKey)
    .doc(studentRef.id);
}

export function getStudentSecretRefFromStudentRef(
  studentRef: firebase.firestore.DocumentReference
): firebase.firestore.DocumentReference {
  if (
    studentRef.parent.id !== studentCollectionKey ||
    !studentRef.parent.parent
  ) {
    throw new Error("given data is not student reference");
  }
  return studentRef.parent.parent
    .collection(studentSecretCollectionKey)
    .doc(studentRef.id);
}

export function getClassroomRefFromStudentRef(
  studentRef: firebase.firestore.DocumentReference
): firebase.firestore.DocumentReference {
  if (
    studentRef.parent.id !== studentCollectionKey ||
    !studentRef.parent.parent
  ) {
    throw new Error("given data is not student reference");
  }
  return studentRef.parent.parent;
}

export function getSchoolRefFromStudentRef(
  studentRef: firebase.firestore.DocumentReference
): firebase.firestore.DocumentReference {
  if (studentRef.parent.id !== studentCollectionKey) {
    throw new Error("given data is not student reference");
  }
  const classroomRef = getClassroomRefFromStudentRef(studentRef);
  return getSchoolRefFromClassroomRef(classroomRef);
}

// student reference の [schoolDocId, classroomDocId, studentDocId] を返す
export function getDocIdsOfStudentRef(
  studentRef: firebase.firestore.DocumentReference
): [string, string, string] {
  if (studentRef.parent.id !== studentCollectionKey) {
    throw new Error("given data is not student reference");
  }

  if (!studentRef.parent.parent) {
    return ["", "", ""];
  }
  const classroomRef = studentRef.parent.parent;
  if (!classroomRef.parent.parent) {
    return ["", "", ""];
  }
  const schoolRef = classroomRef.parent.parent;
  return [schoolRef.id, classroomRef.id, studentRef.id];
}

// student の [schoolDocId, classroomDocId, studentDocId] を返す
export function getDocIdsOfStudent(student: Student): [string, string, string] {
  return getDocIdsOfStudentRef(student.ref);
}

export function convertToStudent(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): Student {
  const returnData: StudentData = {
    id: data.id,
    name: data.name,
    grade: data.grade,
    classSchedules: data.classSchedules,
    totalTime: data.totalTime,
    totalDayCount: data.totalDayCount,
    currentRunningDayCount: data.currentRunningDayCount,
    maxRunningDayCount: data.maxRunningDayCount,
    maxRunningWeekCount: data.maxRunningWeekCount,
    latestLearnings: data.latestLearnings,
    latestMessageTime: data.latestMessageTime,
    latestMessageText: data.latestMessageText,
    target: data.target,
    sundayNotify: data.sundayNotify ?? false,
    mondayNotify: data.mondayNotify ?? false,
    tuesdayNotify: data.tuesdayNotify ?? false,
    wednesdayNotify: data.wednesdayNotify ?? false,
    thursdayNotify: data.thursdayNotify ?? false,
    fridayNotify: data.fridayNotify ?? false,
    saturdayNotify: data.saturdayNotify ?? false,
    isNandeUserCreated: data.isNandeUserCreated ?? false,
    isLinkedToQanda: data.isLinkedToQanda ?? false
  };
  if (data.properties) {
    returnData.properties = data.properties;
  }
  if (data.characteristic) {
    returnData.characteristic = data.characteristic;
  }
  if (data.freeInput) {
    returnData.freeInput = data.freeInput;
  }
  if (data.created) {
    returnData.created = data.created;
  }
  if (data.recessTime) {
    returnData.recessTime = data.recessTime;
  }
  if (data.emailReceivers) {
    returnData.emailReceivers = data.emailReceivers;
  }
  if (data.prefecture) {
    returnData.prefecture = data.prefecture;
  }
  if (data.notifiedDayCount) {
    returnData.notifiedDayCount = data.notifiedDayCount;
  }
  if (data.nandeUserId) {
    returnData.nandeUserId = data.nandeUserId;
  }
  if ("unlinkSchoolAI" in data) {
    returnData.unlinkSchoolAI = data.unlinkSchoolAI;
    if (data.unlinkSchoolAIAt) {
      returnData.unlinkSchoolAIAt = data.unlinkSchoolAIAt;
    }
    if (data.relinkSchoolAIAt) {
      returnData.relinkSchoolAIAt = data.relinkSchoolAIAt;
    }
  }
  if (data.qandaUserId) {
    returnData.qandaUserId = data.qandaUserId;
  }

  return {
    ref,
    data: returnData
  };
}

export function convertToStudentConfig(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): StudentConfig {
  const returnData: StudentConfigData = {
    password: data.password
  };
  if (data.lineId) {
    returnData.lineId = data.lineId;
  }
  if (data.loginId) {
    returnData.loginId = data.loginId;
  }

  return {
    ref,
    data: returnData
  };
}

export function convertToStudentSecret(
  data: firebase.firestore.DocumentData,
  ref: firebase.firestore.DocumentReference
): StudentSecret {
  const returnData: StudentSecretData = {
    comment: data.comment
  };

  return {
    ref,
    data: returnData
  };
}
