import { getAdmin, fetchAdminPrompts } from "@/api/admin";
import { getEmail, signIn, signOut } from "@/api/auth";
import {
  fetchClassroomsOfTutor,
  fetchClassroomsOfSchool,
  getchildrenSchoolStudents,
  getChildrenScoolClassRooms
} from "@/api/classroom";
import { fetchCustomPropertiesOfSchool } from "@/api/customProperty";
import { saveErrorLog } from "@/api/error";
import { fetchGroupsOfAdmin, fetchGroupsOfSchool } from "@/api/group";
import {
  fetchSchoolConfigs,
  getSchoolDetail,
  getSchools,
  getSchoolsForServiceProvider
} from "@/api/school";
import {
  fetchStudentSecrets,
  getStudents,
  getStudentsOfClassroom
} from "@/api/student";
import { fetchTargets } from "@/api/target";
import {
  fetchTutorConfig,
  fetchTutorConfigsOfSchool,
  fetchTutorsOfSchool,
  getTutor,
  TutorMeta,
  fetchTutorPrompts
} from "@/api/tutor";
import { Admin } from "@/entities/admin";
import { Classroom } from "@/entities/classroom";
import { CustomProperty } from "@/entities/custom_property";
import { Group } from "@/entities/group";
import { Role } from "@/entities/role";
import { School, SchoolConfig } from "@/entities/school";
import { Student, StudentSecret } from "@/entities/student";
import { Target, convertToTarget } from "@/entities/target";
import { tutorConfigCollectionKey } from "@/entities/tutor";
import ChannelService from "@/plugins/ChannelService";
import router from "@/router";
import firebase from "firebase/app";
import { createStore } from "vuex";
import messagePanel from "./modules/messagePanel";
import messageReservation from "./modules/messageReservation";
import messageTemplateSheet from "./modules/messageTemplateSheet";
import room from "./modules/room";
import stickerSheet from "./modules/stickerSheet";
import student from "./modules/student";
import studentList from "./modules/studentList";
import submissionMaster from "./modules/submissionMaster";
import serviceTopic from "./modules/serviceTopic";
import { MiniApp } from "@/entities/mini_app";
import { getMiniApps, getMiniAppsOfSchool } from "@/api/mini_app";
import { MainButtonSettingType } from "@/entities/main_button_setting";
import { QandaChatHistory } from "@/entities/qanda";
import {
  getMainButtonSetting,
  getMainButtonSettingOfSchool
} from "@/api/main_button_setting";
import { getQandaMessages } from "@/api/qanda";
import { Lang } from "@/entities/lang";
import { getLangFromLocalStorage, setLangInLocalStorage } from "@/utils/lang";
import i18n from "@/lang/index";

export type LoginInfo = {
  schoolName: string;
  studentName: string;
  loginId: string;
  password: string;
  schoolId: string;
};

export type TutorPrompt = {
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  data: {
    name: string;
    text: string;
  };
} | null;

export type AdminPrompt = {
  ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  data: {
    name: string;
    text: string;
  };
} | null;

export interface RootState {
  loading: boolean;
  loadText: string;
  bootedChannel: boolean;
  isOpenUserModal: boolean;
  role: Role | null;
  tutor: TutorMeta | null;
  admin: Admin | null;
  adminPrompts: AdminPrompt[] | null;
  school: School | null;
  schoolConfig: SchoolConfig | null;
  classrooms: Classroom[];
  tutors: TutorMeta[];
  tutorPrompts: TutorPrompt[] | null;
  customProperties: CustomProperty[];
  groups: Group[];
  schools: School[];
  schoolsForServieProvider: School[];
  schoolConfigs: SchoolConfig[];
  target: Target | null;
  isSchool: boolean; //領域が「学校」であるか
  targets: Target[];
  students: Student[];
  studentSecrets: StudentSecret[];
  studentsOfSchool: Student[];
  selectedLoginInfos: LoginInfo[];
  miniApps: MiniApp[];
  mainButtonSetting: MainButtonSettingType[];
  qandaChatHistory: QandaChatHistory;
  tutorPasswordChangeRequired: boolean;
  isSigningOut: boolean;
  loginExecuted: boolean;
  isFetchingRole: boolean;
  lang: Lang;
  translatedValue: (key: string, params?: any) => string;
}

export default createStore<RootState>({
  state: {
    loading: false,
    loadText: "",
    bootedChannel: false,
    isOpenUserModal: false,
    role: null,
    tutor: null,
    tutorPrompts: null,
    admin: null,
    adminPrompts: null,
    school: null,
    schoolConfig: null,
    classrooms: [],
    tutors: [],
    customProperties: [],
    groups: [],
    schools: [],
    schoolsForServieProvider: [],
    schoolConfigs: [],
    target: null,
    isSchool: false,
    targets: [],
    students: [],
    studentSecrets: [],
    studentsOfSchool: [],
    selectedLoginInfos: [],
    miniApps: [],
    mainButtonSetting: [],
    qandaChatHistory: null,
    tutorPasswordChangeRequired: false,
    isSigningOut: false,
    loginExecuted: false,
    isFetchingRole: false,
    lang: getLangFromLocalStorage() as Lang,
    translatedValue: (key: string, params?: any) => i18n.t(key, params)
  },
  getters: {
    /*
    各管理人の役割はこちらを参照
    https://docs.google.com/spreadsheets/d/1VBmlbR90vhMIR7so4KJxlP5GOhmsCO9nmcyyDX0Vl2M/edit#gid=1885671486
    */
    // みんがく管理人のadminであるか
    isAdmin: state => !!state.admin,
    // みんがく管理人のSuperAdminであるか
    isSuperAdmin: state => !!state.admin && !!state.admin.isSuperAdmin,
    // 塾のオーナー or 管理者であるか
    isAdminOfSchool: state =>
      !!state.school &&
      !!state.role &&
      state.school.data.admins.includes(state.role.ref.id),
    // サービス事業者であるか
    isServiceProvider: state =>
      !!state.role &&
      !!state.school &&
      !!state.schoolConfig &&
      !!state.schoolConfig.data.role &&
      (state.schoolConfig.data.role.includes("serviceProvider") ?? false),
    // 塾の責任者であるか
    isSupervisorOfSchool: state =>
      !!state.school &&
      !!state.role &&
      (state.school.data.supervisors?.includes(state.role.ref.id) ?? false),
    // 塾の担当者であるか
    isEmployeeOfSchool: state =>
      !!state.school &&
      !!state.role &&
      (state.school.data.employees?.includes(state.role.ref.id) ?? false),
    // 塾のTutorであるか
    isTutorOfSchool: state =>
      !!state.school &&
      !!state.role &&
      (state.school.data.tutors?.includes(state.role.ref.id) ?? false),
    // 独自ルームの作成権限を持っている塾であるか
    allowCreateRoom: state =>
      !!state.role &&
      !!state.school &&
      !!state.schoolConfig &&
      !!state.schoolConfig.data.allowCreateRoom &&
      !!state.school.data.tutors.includes(state.role.ref.id),
    isCramSchool: state =>
      !!state.schoolConfig &&
      !!state.target &&
      state.target.data.key === "cram_school",
    isExclusiveSchoolAiUser: state =>
      !!state.schoolConfig &&
      (state.schoolConfig.data.useSchoolAiExclusively ?? false)
  },
  mutations: {
    START_LOADING(state, text: string) {
      state.loading = true;
      state.loadText = text;
    },
    END_LOADING(state) {
      state.loading = false;
      state.loadText = "";
    },
    BOOT_CHANNEL(state) {
      state.bootedChannel = true;
    },
    SET_IS_OPEN_USER_MODAL(state, isOpen: boolean) {
      state.isOpenUserModal = isOpen;
    },
    SET_ROLE(state, role: Role) {
      state.role = role;
    },
    SET_TUTOR(state, tutor: TutorMeta) {
      state.tutor = tutor;
    },
    SET_TUTOR_PROMPTS(state, tutorPrompts: TutorPrompt[]) {
      state.tutorPrompts = tutorPrompts;
    },
    SET_ADMIN(state, admin: Admin) {
      state.admin = admin;
    },
    SET_ADMIN_PROMPTS(state, adminPrompts: AdminPrompt[]) {
      state.adminPrompts = adminPrompts;
    },
    SET_SCHOOL(state, school: School) {
      state.school = school;
    },
    SET_SCHOOL_CONFIG(state, config: SchoolConfig) {
      state.schoolConfig = config;
    },
    SET_CLASSROOMS(state, classrooms: Classroom[]) {
      state.classrooms = classrooms;
    },
    SET_TUTORS(state, tutors: TutorMeta[]) {
      state.tutors = tutors;
    },
    SET_TUTOR_PASSWORD_REQUIRED(state, flag: boolean) {
      state.tutorPasswordChangeRequired = flag;
    },
    SET_CUSTOM_PROPERTIES(state, customProperties: CustomProperty[]) {
      state.customProperties = customProperties;
    },
    SET_GROUPS(state, groups: Group[]) {
      state.groups = groups;
    },
    SET_SCHOOLS(state, schools: School[]) {
      state.schools = schools;
    },
    SET_SCHOOLS_FOR_SERVICE_PROVIDER(state, schools: School[]) {
      state.schoolsForServieProvider = schools;
    },
    SET_SCHOOL_CONFIGS(state, configs: SchoolConfig[]) {
      state.schoolConfigs = configs;
    },
    SET_TARGET(state, target: Target) {
      state.target = target;
    },
    SET_TARGETS(state, targets: Target[]) {
      state.targets = targets;
    },
    SET_IS_SCHOOL(state, isSchool: boolean) {
      state.isSchool = isSchool;
    },
    SET_STUDENTS(state, students: Student[]) {
      state.students = students;
    },
    SET_STUDENT_SECRETS(state, secrets: StudentSecret[]) {
      state.studentSecrets = secrets;
    },
    SET_STUDENTS_OF_SCHOOL(state, students: Student[]) {
      state.studentsOfSchool = students;
    },
    SET_SELECTED_LOGIN_INFOS(state, infos: LoginInfo[]) {
      state.selectedLoginInfos = infos;
    },
    SET_MAIN_BUTTON_SETTING(state, mainButtonSetting: MainButtonSettingType[]) {
      state.mainButtonSetting = mainButtonSetting;
    },
    SET_QANDA_CHAT_HISTORY(state, qandaChatHistory: QandaChatHistory) {
      state.qandaChatHistory = qandaChatHistory;
    },
    SET_APPS(state, miniApps: MiniApp[]) {
      state.miniApps = miniApps;
    },
    SET_SIGNING_OUT(state, isSigningOut: boolean) {
      state.isSigningOut = isSigningOut;
    },
    SET_LOGIN_EXECUTED(state, loginExecuted: boolean) {
      state.loginExecuted = loginExecuted;
    },
    SET_IS_FETCHING_ROLE(state, isFetchingRole: boolean) {
      state.isFetchingRole = isFetchingRole;
    },
    SET_LANG(state, lang: Lang) {
      state.lang = lang;
    },
    CLEAR(state) {
      state.isOpenUserModal = false;
      state.role = null;
      state.tutor = null;
      state.admin = null;
      state.school = null;
      state.schoolConfig = null;
      state.classrooms = [];
      state.tutors = [];
      state.customProperties = [];
      state.groups = [];
      state.schools = [];
      state.schoolConfigs = [];
      state.targets = [];
      state.students = [];
      state.studentSecrets = [];
      state.selectedLoginInfos = [];
    }
  },
  actions: {
    async signIn(context, { email, password }) {
      context.commit("START_LOADING", "ログイン処理中...");
      try {
        context.commit("SET_LOGIN_EXECUTED", true);
        const role = await signIn(email, password);
        context.commit("SET_ROLE", role);
        await context.dispatch("getUserInfo", role);
      } catch (e) {
        context.commit("END_LOADING");
        alert(
          `ログインに失敗しました。\nメールアドレス・パスワードを確認の上、再度入力してみてください。\n\n${e}`
        );
        await saveErrorLog(null, e.code, e.message, "Failed to sign in");
        context.commit("SET_LOGIN_EXECUTED", false);
        return;
      }

      context.commit("END_LOADING");
      router.replace("/");
    },
    async getUserInfo(context, role: Role) {
      if (role.data.type === "admin") {
        try {
          const admin = await getAdmin(role.ref.id);
          if (!admin) {
            alert("ユーザーが見つかりませんでした");
            await saveErrorLog(
              role,
              "not_found",
              "Failed to get admin",
              "Failed to get admin"
            );
            return;
          }
          const adminPrompts = await fetchAdminPrompts(role.ref.id);
          context.commit("SET_ADMIN", admin);
          context.commit("SET_ADMIN_PROMPTS", adminPrompts);
          context.dispatch("getGroups");
          context.dispatch("getStudentSecrets");
        } catch (e) {
          alert(`ユーザー情報の取得に失敗しました\n\n${e}`);
          await saveErrorLog(role, e.code, e.message, "Failed to get admin");
          return;
        }
      } else if (role.data.type === "tutor") {
        const [tutor, tutorConfig, tutorPrompts] = await Promise.all([
          getTutor(role.data.ref),
          fetchTutorConfig(
            role.data.ref.parent
              .parent!.collection(tutorConfigCollectionKey)
              .doc(role.data.ref.id)
          ),
          fetchTutorPrompts(role.data.ref)
        ]);
        if (!tutor || !tutorConfig) {
          return;
        }
        const tutorMeta: TutorMeta = {
          main: tutor,
          config: tutorConfig
        };
        context.commit("SET_TUTOR", tutorMeta);
        context.commit("SET_TUTOR_PROMPTS", tutorPrompts);

        const [school, schoolConfig] = await getSchoolDetail(
          true, // 権限の要件変更に伴い、全権限でschoolConfigのデータを参照する必要があるため,trueに設定
          tutor.ref.parent.parent!.id
        );

        if (school) {
          const classrooms = await fetchClassroomsOfSchool(school.ref);
          const studentsOfClassrooms = await Promise.all(
            classrooms.map(item => getStudentsOfClassroom(item.ref))
          );
          const students = studentsOfClassrooms
            .filter(item => item !== null)
            .reduce((pre, current) => {
              pre.push(...current);
              return pre;
            }, []);
          context.commit("SET_STUDENTS_OF_SCHOOL", students);
          context.commit("SET_SCHOOL", school);
          context.dispatch("getClassrooms", school.ref);
          context.dispatch("getTutors", school.ref);
          context.dispatch("getCustomProperties", school.ref);
          context.dispatch("getGroups", school.ref);
        }
        if (schoolConfig) {
          context.commit("SET_SCHOOL_CONFIG", schoolConfig);
          const target = convertToTarget(
            (await schoolConfig!.data.target.get()).data()!,
            schoolConfig!.data.target
          );
          context.commit("SET_TARGET", target);
          context.commit("SET_IS_SCHOOL", target.data.key === "school");
        }
      } else {
        throw new Error("This user is not authorized to login");
      }
      context.dispatch("getStudents");
      context.dispatch("getSchools");
      context.dispatch("getSchoolsForServicePrivider");
      context.dispatch("getSchoolConfigs");
      context.dispatch("getTargets");
      context.dispatch("bootChannelTalk");

      if (role.data.type === "tutor") {
        const passwordChangeRequired =
          context.state.tutor?.config.data.passwordChangeRequired ?? false;
        if (passwordChangeRequired) {
          setTimeout(() => {
            context.commit("SET_TUTOR_PASSWORD_REQUIRED", true);
          }, 2000);
        }
      }
    },
    async bootChannelTalk(context) {
      const isChildSchool: boolean = (() => {
        const schoolConfig = context.state.schoolConfig;
        if (!schoolConfig) return false;
        const parentSchools = schoolConfig.data.parentSchoolDocIds;
        if (
          !parentSchools ||
          !Array.isArray(parentSchools) ||
          parentSchools.length === 0
        ) {
          return false;
        }
        return true;
      })();

      // みんがく利用のtutor以外の場合および子スクールの場合は channel talk を起動しない
      if (
        !context.state.role ||
        context.state.role.data.type !== "tutor" ||
        !context.state.tutor ||
        !context.state.school ||
        isChildSchool ||
        context.state.bootedChannel
      ) {
        return;
      }

      context.commit("BOOT_CHANNEL");

      const name = context.state.tutor.main.data.name;
      const schoolName = context.state.school.data.name;
      const schoolId = context.state.school.data.id;
      const email = getEmail();
      const id = context.state.role.ref.id;
      if (context.state.schoolConfig) {
        ChannelService.boot({
          id,
          pluginKey: "e82d60ee-f3f8-4b12-9e83-4e55eff0132d",
          memberId: id,
          language: "ja",
          profile: {
            name,
            email,
            schoolName,
            schoolId,
            customerEmail: context.state.schoolConfig.data.customerEmail
          }
        });
      } else {
        ChannelService.boot({
          id,
          pluginKey: "e82d60ee-f3f8-4b12-9e83-4e55eff0132d",
          memberId: id,
          language: "ja",
          profile: {
            name,
            email,
            schoolName,
            schoolId
          }
        });
      }
    },
    async signOut(context) {
      try {
        context.commit("START_LOADING", "ログアウト中...");
        await signOut();
      } catch (e) {
        alert(`ログアウトに失敗しました\n\n${e}`);
        await saveErrorLog(
          context.state.role,
          e.code,
          e.message,
          "Failed to sign out"
        );
        return;
      }
      context.commit("END_LOADING");
      router.go(0);
    },
    async getClassrooms(
      context,
      schoolRef: firebase.firestore.DocumentReference
    ) {
      if (
        !context.state.role ||
        context.state.role.data.type !== "tutor" ||
        !context.state.tutor
      ) {
        return;
      }

      const tutorConfig = context.state.tutor.config;

      try {
        const classrooms = await fetchClassroomsOfTutor(
          tutorConfig.ref.id,
          schoolRef
        );
        if (
          context.state.schoolConfig?.data.role?.includes("serviceProvider")
        ) {
          const childrenScoolClassRooms = await getChildrenScoolClassRooms(
            context.state.school!.ref.id
          );
          classrooms.push(...childrenScoolClassRooms);
        }
        if (!classrooms || classrooms.length === 0) {
          return;
        }
        context.commit("SET_CLASSROOMS", classrooms);
      } catch (e) {
        alert(`教室情報の取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.state.role,
          e.code,
          e.message,
          "Failed to get classroom"
        );
        return;
      }
    },
    async getTutors(context, schoolRef: firebase.firestore.DocumentReference) {
      if (
        !context.state.role ||
        context.state.role.data.type !== "tutor" ||
        !context.state.tutor ||
        context.state.tutor.config.data.authority === "general"
      ) {
        return;
      }

      const [tutors, tutorConfigs] = await Promise.all([
        fetchTutorsOfSchool(schoolRef),
        fetchTutorConfigsOfSchool(schoolRef)
      ]);
      const tutorMetas: TutorMeta[] = [];
      for (const tutor of tutors) {
        for (const tutorConfig of tutorConfigs) {
          if (tutor.ref.id === tutorConfig.ref.id) {
            tutorMetas.push({
              main: tutor,
              config: tutorConfig
            });
            break;
          }
        }
      }
      context.commit("SET_TUTORS", tutorMetas);
    },
    async getCustomProperties(
      context,
      schoolRef: firebase.firestore.DocumentReference
    ) {
      try {
        const customProperties = await fetchCustomPropertiesOfSchool(schoolRef);
        context.commit("SET_CUSTOM_PROPERTIES", customProperties);
      } catch (e) {
        alert(`オリジナル情報の取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.state.role,
          e.code,
          e.message,
          "Failed to get custom properties"
        );
      }
    },
    async getGroups(context, schoolRef?: firebase.firestore.DocumentReference) {
      if (schoolRef) {
        const groups = await fetchGroupsOfSchool(schoolRef);
        context.commit("SET_GROUPS", groups);
      } else {
        const groups = await fetchGroupsOfAdmin();
        context.commit("SET_GROUPS", groups);
      }
    },
    async getStudents(context) {
      if (!context.state.role) {
        return;
      }

      let students: Student[];
      if (context.state.role.data.type === "admin") {
        try {
          students = await getStudents();
        } catch (e) {
          alert(`生徒情報の取得に失敗しました\n\n${e}`);
          await saveErrorLog(
            context.state.role,
            e.code,
            e.message,
            "Failed to get students"
          );
          return;
        }
      } else if (
        context.state.role.data.type === "tutor" &&
        context.state.tutor &&
        context.state.school
      ) {
        try {
          const classrooms = await fetchClassroomsOfTutor(
            context.state.tutor.main.ref.id,
            context.state.school.ref
          );
          const studentsOfClassrooms = await Promise.all(
            classrooms.map(item => getStudentsOfClassroom(item.ref))
          );
          students = studentsOfClassrooms
            .filter(item => item !== null)
            .reduce((pre, current) => {
              pre.push(...current);
              return pre;
            }, []);

          if (
            context.state.schoolConfig?.data.role?.includes("serviceProvider")
          ) {
            const childrenSchoolStudents = await getchildrenSchoolStudents(
              context.state.school.ref.id
            );

            students.push(...childrenSchoolStudents);
          }
        } catch (e) {
          alert(`生徒情報の取得に失敗しました\n\n${e}`);
          await saveErrorLog(
            context.state.role,
            e.code,
            e.message,
            "Failed to get students"
          );
          return;
        }
      } else {
        return;
      }

      context.commit("SET_STUDENTS", students);
    },
    async getStudentSecrets(context) {
      try {
        const secrets = await fetchStudentSecrets();
        context.commit("SET_STUDENT_SECRETS", secrets);
      } catch (e) {
        console.error(e);
      }
    },
    async getSchools(context) {
      if (!context.state.role) {
        return;
      }

      if (
        context.state.role.data.type !== "admin" &&
        !context.state.schoolConfig?.data.role?.includes("serviceProvider")
      ) {
        return;
      }

      try {
        const schools = await getSchools();
        context.commit("SET_SCHOOLS", schools);
      } catch (e) {
        alert(`導入塾情報の取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.state.role,
          e.code,
          e.message,
          "Failed to get schools"
        );
      }
    },
    async getSchoolsForServicePrivider(context) {
      if (!context.state.role) {
        return;
      }

      if (!context.state.schoolConfig?.data.role?.includes("serviceProvider")) {
        return;
      }

      try {
        const schools: School[] = await getSchoolsForServiceProvider(
          context.state.school!.ref.id
        );
        context.commit("SET_SCHOOLS_FOR_SERVICE_PROVIDER", schools);
      } catch (e) {
        alert(`サービス事業者導入塾情報の取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.state.role,
          e.code,
          e.message,
          "Failed to get schools"
        );
      }
    },
    async getSchoolConfigs(context) {
      if (!context.state.role) {
        return;
      }
      if (
        context.state.role.data.type !== "admin" &&
        context.state.role.data.type !== "tutor"
      ) {
        return;
      }

      try {
        const configs = await fetchSchoolConfigs();
        context.commit("SET_SCHOOL_CONFIGS", configs);
      } catch (e) {
        alert(e);
        await saveErrorLog(
          context.state.role,
          e.code,
          e.message,
          "Failed to get school configs"
        );
      }
    },
    async getTargets(context) {
      if (!context.state.role) {
        return;
      }
      if (
        context.state.role.data.type !== "admin" &&
        !context.state.schoolConfig?.data.role?.includes("serviceProvider")
      ) {
        return;
      }
      try {
        const targets = await fetchTargets();
        context.commit("SET_TARGETS", targets);
      } catch (e) {
        alert(e);
        await saveErrorLog(
          context.state.role,
          e.code,
          e.message,
          "Failed to get targets"
        );
      }
    },
    async getQandaQuestions(context, student: Student) {
      if (!context.state.role || !student) {
        return;
      }
      try {
        const qandaChatHistory: QandaChatHistory = await getQandaMessages(
          student
        );
        context.commit("SET_QANDA_CHAT_HISTORY", qandaChatHistory);
      } catch (e) {
        alert(`情報の取得に失敗しました\n\n${e}`);
        await saveErrorLog(
          context.state.role,
          e.code,
          e.message,
          "Failed to get students"
        );
        return;
      }
      // here
    },
    async getMainButtonSetting(context) {
      if (!context.state.role) {
        return;
      }
      let mainButtonSetting: MainButtonSettingType[];
      if (context.state.role.data.type === "admin") {
        try {
          mainButtonSetting = await getMainButtonSetting();
        } catch (e) {
          alert(`メインボタン情報の取得に失敗しました\n\n${e}`);
          await saveErrorLog(
            context.state.role,
            e.code,
            e.message,
            "Failed to get students"
          );
          return;
        }
      } else if (
        context.state.role.data.type === "tutor" &&
        !!context.state.school
      ) {
        try {
          const schoolRef = context.state.school.ref;
          mainButtonSetting = await getMainButtonSettingOfSchool(schoolRef);
        } catch (e) {
          alert(`メインボタン情報の取得に失敗しました\n\n${e}`);
          await saveErrorLog(
            context.state.role,
            e.code,
            e.message,
            "Failed to get students"
          );
          return;
        }
      } else {
        return;
      }
      context.commit("SET_MAIN_BUTTON_SETTING", mainButtonSetting);
    },
    async getMiniApps(context) {
      if (!context.state.role) {
        return;
      }
      let miniApps: MiniApp[];
      if (context.state.role.data.type === "admin") {
        try {
          miniApps = await getMiniApps();
        } catch (e) {
          alert(`独自アプリ一覧情報の取得に失敗しました\n\n${e}`);
          await saveErrorLog(
            context.state.role,
            e.code,
            e.message,
            "Failed to get students"
          );
          return;
        }
      } else if (
        context.state.role.data.type === "tutor" &&
        !!context.state.school
      ) {
        try {
          const schoolRef = context.state.school.ref;
          miniApps = await getMiniAppsOfSchool(schoolRef);
        } catch (e) {
          alert(`独自アプリ一覧情報の取得に失敗しました\n\n${e}`);
          await saveErrorLog(
            context.state.role,
            e.code,
            e.message,
            "Failed to get students"
          );
          return;
        }
      } else {
        return;
      }
      context.commit("SET_APPS", miniApps);
    },
    setLanguage(context, lang: Lang) {
      context.commit("SET_LANG", lang);
      setLangInLocalStorage(lang);
      i18n.setLocale(lang);
    }
  },
  modules: {
    messagePanel,
    messageReservation,
    messageTemplateSheet,
    room,
    stickerSheet,
    student,
    studentList,
    submissionMaster,
    serviceTopic
  }
});
