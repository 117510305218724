import { convertToRole, Role, roleCollectionKey } from "@/entities/role";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import axios from "axios";
import store from "@/store";

export async function checkAuthSessionState(): Promise<{
  uid: string;
  expiresAt: number;
  notFound?: boolean;
}> {
  try {
    const res = await axios.get("/check_auth_session", {
      withCredentials: true
    });
    return {
      uid: res.data.uid ?? "",
      expiresAt: res.data.expiresAt ?? 0,
      notFound: res.data.not_found ?? false
    };
  } catch (e) {
    console.error("error when fetching auth state", e);
    return { uid: "", expiresAt: 0 };
  }
}

export async function createAuthSession(idToken: string) {
  if (!idToken) return { ok: false };
  const res = await axios.post("/create_auth_session", { idToken });
  if (res.data?.status !== "success") {
    throw new Error("failed to create session");
  }

  return { ok: true };
}

export async function attemptCreateAuthSession(idToken: string) {
  try {
    await createAuthSession(idToken);
  } catch (e) {
    console.error("Failed to create auth session", e);
  }
}

export async function destroyAuthSession() {
  const res = await axios.post(
    "/destroy_auth_session",
    {},
    { withCredentials: true }
  );
  if (res.data?.status !== "success") {
    throw new Error("failed to remove session");
  }
}

export async function createCustomToken(
  uid: string
): Promise<{ token: string }> {
  if (!uid) return { token: "" };
  const call = firebase
    .app()
    .functions("asia-northeast1")
    .httpsCallable("create_custom_token");
  const res = await call({ uid });
  return { token: res.data.customToken ?? "" };
}

export async function signIn(email: string, password: string): Promise<Role> {
  const auth = firebase.auth();
  const db = firebase.firestore();

  const userCredential = await auth.signInWithEmailAndPassword(email, password);

  const firebaseUser = userCredential.user;
  if (!firebaseUser) {
    throw new Error("Failed to get user");
  }

  const idToken = await firebaseUser.getIdToken();
  if (!idToken) {
    throw new Error("token id not found");
  }

  await attemptCreateAuthSession(idToken);

  const snapshot = await db
    .collection(roleCollectionKey)
    .doc(firebaseUser.uid)
    .get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    throw new Error("Failed to get role data");
  }
  return convertToRole(data, snapshot.ref);
}

export async function signOut(): Promise<void> {
  try {
    store.commit("SET_SIGNING_OUT", true);
    try {
      await destroyAuthSession();
    } catch (e) {
      console.error("セッション破棄エラー", e);
    }
    await firebase.auth().signOut();
  } finally {
    store.commit("SET_SIGNING_OUT", false);
  }
}

export async function getRole(id: string): Promise<Role | null> {
  const snapshot = await firebase
    .firestore()
    .collection(roleCollectionKey)
    .doc(id)
    .get();
  const data = snapshot.data();
  if (!snapshot.exists || !data) {
    return null;
  }
  return convertToRole(data, snapshot.ref);
}

export function getEmail(): string {
  const user = firebase.auth().currentUser;
  if (!user) {
    return "";
  }

  return user.email ?? "";
}

export function isVerifiedEmail(): boolean {
  const user = firebase.auth().currentUser;
  if (!user) {
    return false;
  }
  return user.email !== null && user.emailVerified;
}

export async function updateEmail(email: string): Promise<void> {
  const user = firebase.auth().currentUser;
  if (!user) {
    throw new Error("ユーザー情報の取得に失敗しました");
  }

  await user.updateEmail(email);
}

export async function sendEmailVerification(): Promise<void> {
  const user = firebase.auth().currentUser;
  if (!user) {
    throw new Error("ユーザー情報の取得に失敗しました");
  }
  await user.sendEmailVerification();
}

export async function sendResetPasswordEmail(email: string): Promise<void> {
  await firebase.auth().sendPasswordResetEmail(email);
}
