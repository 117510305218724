import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-28" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SelectBox = _resolveComponent("SelectBox")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_SelectBox, {
      key: "value",
      name: "toggle-box",
      options: _ctx.options,
      value: _ctx.selectedLang,
      placeholder: _ctx.translatedValue('language'),
      "value-key": "value",
      "text-key": "text",
      onChange: _ctx.handleLangChange
    }, null, 8, ["options", "value", "placeholder", "onChange"])
  ]))
}