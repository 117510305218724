
import { Options, Vue } from "vue-class-component";
import SelectBox from "@/components/form/MSelectBox.vue";
import { Lang } from "@/entities/lang";
import store from "@/store";

@Options({
  emits: ["click"],
  props: {},
  components: { SelectBox }
})
export default class MLangToggleBox extends Vue {
  options = [
    { text: "日本語", value: "ja" },
    { text: "English", value: "en" }
  ];

  get translatedValue() {
    return store.state.translatedValue;
  }

  get selectedLang(): Lang {
    return store.state.lang;
  }

  handleLangChange(lang: string) {
    store.dispatch("setLanguage", lang);
  }
}
