export type SchoolAiConfig = {
  headerIconImagePath: string;
  headerIconImageUrl: string;
  headerTitle: string;
  pageTitle: string;
  updatedAt: number;
  canViewUsageData?: boolean; //子スクール用データ: 会話データ出力画面において、使用料・料金周りを見れるか(親スクール OR 管理人が設定する)
};

export function convertToSchoolAiConfig(
  schoolConfigData: SchoolAiConfig | undefined
): SchoolAiConfig {
  if (!schoolConfigData) {
    return {
      headerIconImagePath: "",
      headerIconImageUrl: "",
      headerTitle: "",
      pageTitle: "",
      updatedAt: 0,
      canViewUsageData: true
    };
  } else {
    return {
      headerIconImagePath: schoolConfigData.headerIconImagePath ?? "",
      headerIconImageUrl: schoolConfigData.headerIconImageUrl ?? "",
      headerTitle: schoolConfigData.headerTitle ?? "",
      pageTitle: schoolConfigData.pageTitle ?? "",
      updatedAt: schoolConfigData.updatedAt ?? 0,
      canViewUsageData: schoolConfigData.canViewUsageData ?? true
    };
  }
}
