import { createI18n } from "vue3-i18n";
import { ja } from "./langs/ja";
import { en } from "./langs/en";
import { getLangFromLocalStorage } from "@/utils/lang";
import { Lang } from "@/entities/lang";

const i18n = createI18n({
  locale: getLangFromLocalStorage() as Lang,
  messages: {
    en,
    ja
  }
});

export default i18n;
